import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { getLanguageBasedOnBaseURL } from '@/helpers/language-helpers'

// Locales
import en from '@/locales/en.json'
import enUS from '@/locales/en-US.json'
import enCA from '@/locales/en-CA.json'
import enNZ from '@/locales/en-NZ.json'
import enGB from '@/locales/en-GB.json'
import enAU from '@/locales/en-AU.json'

Vue.use(VueI18n)

// https://kazupon.github.io/vue-i18n/api/#constructor-options
export default new VueI18n({
  // locale: The locale of localization. If the locale contains a territory and a dialect, this locale contains an implicit fallback.
  locale: getLanguageBasedOnBaseURL(),
  // messages: The locale messages of localization.
  messages: {
    en,
    'en-US': enUS,
    'en-CA': enCA,
    'en-NZ': enNZ,
    'en-GB': enGB,
    'en-AU': enAU,
  },
  numberFormats: {
    'en-US': {
      currency: {
        style: 'currency',
        currency: 'USD',
      },
    },
    'en-AU': {
      currency: {
        style: 'currency',
        currency: 'AUD',
      },
    },
    'en-NZ': {
      currency: {
        style: 'currency',
        currency: 'NZD',
      },
    },
    'en-GB': {
      currency: {
        style: 'currency',
        currency: 'GBP',
      },
    },
    'en-CA': {
      currency: {
        style: 'currency',
        currency: 'CAD',
      },
    },
  },
  dateTimeFormats: {
    en: {
      time: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      },
      time24: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false,
      },
      dateShort: {
        day: 'numeric',
        month: 'short',
      },
      dateFormatted: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      dateTimeLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'en-AU': {
      dateShort: {
        day: 'numeric',
        month: 'short',
      },
      dateFormatted: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      dateTimeLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'en-NZ': {
      dateShort: {
        day: 'numeric',
        month: 'short',
      },
      dateFormatted: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      dateTimeLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'en-GB': {
      dateShort: {
        day: 'numeric',
        month: 'short',
      },
      dateFormatted: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      dateTimeLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'en-CA': {
      dateShort: {
        day: 'numeric',
        month: 'short',
      },
      dateFormatted: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      dateTimeLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
    'en-US': {
      dateShort: {
        day: 'numeric',
        month: 'short',
      },
      dateFormatted: {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      },
      dateTimeLong: {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      },
    },
  },
  // silentTranslationWarn: Whether suppress warnings outputted when localization fails.
  silentTranslationWarn: true,
  // silentFallbackWarn: Whether suppress fallback warnings when localization fails.
  silentFallbackWarn: true,
})
