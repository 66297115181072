import { ModelApiService } from './BaseApiService'

export default class CertificationsApiService extends ModelApiService {
  constructor() {
    super('certifications')
  }

  async getCertTypes() {
    return await this.get('types')
  }

  /**
   * @param {{certificationId: Number, accessGui: String}} payload
   * @returns {Promise<ResultDTO>} Blob of the certification file
   */
  async downloadFile({ certificationId, accessGui }) {
    return this.getBlob(`${certificationId}/file/${accessGui}`)
  }
}
